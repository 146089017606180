<template>
  <div class="border-input" :class="{ active: isInputActive }">
    <input
      class="border-input-input"
      type="text"
      ref="input"
      :placeholder="placeholder"
      v-model="value"
      @blur="onInputBlur"
      @click="onInputClick"
      @input="onInputInput"
    />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    initalValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
  },

  data: function () {
    return {
      value: "",
      isInputActive: false,
    };
  },

  created: function () {
    this.value = this.initalValue;
  },

  watch:{
    initalValue(value){
      this.value = value
    }
  },

  methods: {
    onInputBlur: function () {
      this.isInputActive = false;
    },

    onInputClick: function () {
      this.isInputActive = true;
    },

    onInputInput: function () {
      this.isInputActive = true;
      this.$emit("onInput", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>


.border-input {
  background-color: white;
  border: 1px solid #dcdfe6;
  border-radius: rem(4);
  height: 100%;
  box-sizing: border-box;
  padding-left: rem(15);
  padding-right: rem(12);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-input {
    color: black;
    opacity: 0.65;
    font-size: rem(14);
    border: none; // 去除未选中状态边框
    outline: none; // 去除选中状态边框
    flex: 1;
    width: 100%;
  }

  &-input::-webkit-input-placeholder {
    color: #909399;
    font-size: rem(14);
  }
}

.active {
  border: 1px solid #325FAC;
}
</style>