<template>
  <div class="must-label">
    <p v-if="isMust" class="must-label-icon">*</p>
    <p class="must-label-text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    isMust: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

.must-label {
  font-size: rem(14);
  display: flex;

  &-icon {
    color: #f45c50;
  }

  &-text {
    color: rgba($color: black, $alpha: 0.85);
  }
}
</style>