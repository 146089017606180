<template>
  <div class="item-bar">
    <div class="item-bar-unit name">姓名</div>
    <div class="item-bar-unit gender">性别</div>
    <div class="item-bar-unit age">年龄</div>
    <div class="item-bar-unit phone-number">手机号</div>
    <div class="item-bar-unit monitor-status">睡眠监测状态</div>
    <div class="item-bar-unit organization">机构</div>
    <div class="item-bar-unit operation">操作</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>


.item-bar {
  background-color: #fafafa;
  border-bottom: rem(1) solid #e8e8e8;
  width: 100%;
  height: rem(54);
  position: relative;
  display: flex;

  &-unit {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(14);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    width: 8%;
  }

  .gender {
    width: 8%;
  }

  .age {
    width: 8%;
  }

  .phone-number {
    width: 13%;
  }

  .monitor-status {
    width: 18%;
  }

  .organization {
    width: 20%;
  }

  .operation {
    width: 25%;
  }
}
</style>