<template>
  <div class="cell">
    <div class="cell-left">
      <div class="cell-left-gray-line-top" :class="{ white: isFirst }"></div>
      <img class="cell-left-icon" src="@imgs/blue-dot.png" />
      <div class="cell-left-gray-line-bottom" :class="{ white: isLast }"></div>
    </div>
    <p class="cell-date" :style="{ color: dateColor }">{{ dateText }}</p>
    <p class="cell-ahi" :style="{ color: ahiColor }">{{ ahiText }}</p>
    <button class="cell-button" :class="[isValid ? 'button-valid' : 'button-inValid']" @click="onPreviewClick" :style="{color: isOxygenDeficiencyView && model.complete === '缺少血氧' ? '#325FAC' : '', textDecoration: isOxygenDeficiencyView && model.complete === '缺少血氧' ? 'underline' : '' }">
      {{ buttonText }}
    </button>
    <p></p>
  </div>
</template>

<script>
import { DateTool } from "@js/date-tool.js";
import { Loading } from "element-ui";

export default {
  props: {
    isFirst: {
      type: Boolean,
      value: false,
    },
    isLast: {
      type: Boolean,
      value: false,
    },
    model: Object,
    isOxygenDeficiencyView: Boolean,
  },

  computed: {
    dateText: function () {
      return placeholderText(DateTool.millisecondTimestampToStr(this.model.recordDay, "Y-M-D"));
    },

    isValid: function () {
      return this.model.complete == "完整";
      // if (isOxygenDeficiencyView) {
      //   return false
      // } else {
      //   if (this.model.complete == "完整") {
      //     return true
      //   } else if (this.model.complete == "缺少血氧") {
      //     return true
      //   } else {
      //     return false
      //   }
      // }

    },

    dateColor: function () {
      return this.isValid ? "#666666" : "#c1c1c1";
    },

    ahiText: function () {
      if (this.isValid) {
        return "AHI：" + this.model.ahi;
      } else {
        return "AHI：";
      }
    },

    ahiColor: function () {
      if (this.isValid) {
        const ahiLevel = this.model.ahiLevel;
        switch (ahiLevel) {
          case "正常":
            return "#6cc291"; //绿
          case "轻度":
            return "#666666"; //黑
          case "中度":
            return "#325FAC"; //蓝
          case "重度":
            return "#f45c50"; //红
          default:
            return "#c1c1c1";
        }
      } else {
        return "#c1c1c1";
      }
    },

    buttonText: function () {
      if (this.isOxygenDeficiencyView) {
        console.log(this.model.complete);
        if (this.model.complete == "完整") {
          return "打开报告"
        } else if (this.model.complete == "缺少血氧") {

          return "缺少血氧"
        } else {
          return "无效报告"
        }
      } else {
        return this.isValid ? "打开报告" : "无效报告";
      }

    },
  },

  methods: {
    onPreviewClick: function () {
      if (this.isOxygenDeficiencyView) {
        if (this.isValid) {
          this.$emit("onPreviewClick", this.model);
        } else {
          if (this.model.complete == "缺少血氧") {
            this.$emit("onPreviewClick", this.model);
          }
        }

      } else {
        if (this.isValid) {
          this.$emit("onPreviewClick", this.model);
        }
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  font-size: rem(14);
  width: 100%;
  height: rem(42);
  position: relative;
  display: flex;
  align-items: center;

  &-left {
    width: rem(29);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-gray-line-top {
      background-color: #e9e9e9;
      width: rem(2);
      height: rem(16);
    }

    &-icon {
      width: rem(10);
      height: rem(10);
    }

    &-gray-line-bottom {
      background-color: #e9e9e9;
      width: rem(2);
      height: rem(16);
    }
  }

  &-date {
    margin-left: rem(7);
    width: rem(79);
  }

  &-ahi {
    margin-left: rem(48);
  }

  &-button {
    position: absolute;
    right: rem(35);
  }
}

.button {
  &-valid {
    color: #325FAC;
    text-decoration: underline;
  }

  &-inValid {
    color: #c1c1c1;
    text-decoration: none;
  }
  &-blue-text {
  color: blue;
}
}

.white {
  background-color: white;
}
</style>