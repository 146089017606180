<template>
    <div class="paste">
        <div class="paste-title">检测到剪贴板内容</div>
        <div class="paste-subtitle">是否输入以下内容？</div>
        <div class="paste-content">{{ content }}</div>
        <div class="paste-buttons">
            <el-button class="paste-buttons-cancel" @click="onDialogCancel">取消</el-button>
            <el-button  class="paste-buttons-confirm" type="primary" @click="onDialogConfirm">
                确认
            </el-button>
        </div>
    </div>
</template>

<script>
import { Button } from "element-ui";
export default {
    props: {
        content: String
    },
    components: {
        ElButton: Button
    },
    methods:{
        onDialogConfirm(){
            this.$emit("onDialogConfirm", "confirm");
        },
        onDialogCancel(){
            this.$emit("onDialogCancel", "cancel");
        }
    }
};
</script>

<style lang="scss" scoped>
.paste {
    padding: rem(10);
    width: rem(400);
    color: black;
    font-size: rem(16);
    height: auto;
    background-color: #F0F4F9;
    border-radius: rem(10);
    display: flex;
    flex-direction: column;
    &-title{
        text-align: center;
    }
    &-buttons{
        display: flex;
        width: 100%;

        &-cancel{
            margin-left: auto;
          
        }
    }
    &-subtitle{
        margin-top: rem(12);
        font-size: rem(13);
    }
    &-content{
        margin-top: rem(20);
        font-size: rem(13);
        white-space: pre-wrap;
    }
}
</style>