<template>
  <pop-view @onCloseClick="onCloseClick">
    <div class="report-panel">
      <cell
        v-for="(item, index) of dataArray"
        :key="index"
        :model="item"
        :isFirst="index === 0"
        :isLast="index === dataArray.length - 1"
        :isOxygenDeficiencyView= "item.oxygenDeficiencyView == '1'"
        @onPreviewClick="onPreviewClick"
      ></cell>
    </div>
  </pop-view>
</template>

<script>
import PopView from "@c/common/pop-view.vue";
import Cell from "./cell.vue";
import { Loading } from "element-ui";

export default {
  components: {
    PopView,
    Cell,
  },

  props: {
    dataArray: Array,
    oxygenDeficiencyView:Boolean,
  },

  methods: {
    onCloseClick: function () {
    
      this.$emit("onCloseClick");
    },

    onPreviewClick: function (selectedReportModel) {
      this.$emit("onPreviewClick", selectedReportModel);
    },
  },
};
</script>

<style lang="scss" scoped>

.report-panel {
  box-shadow: rem(0) rem(0) rem(6) rem(0) rgba(black, 0.16);
  width: rem(350);
  max-height: rem(221);
  overflow: hidden;
  overflow-y: auto;
}
</style>