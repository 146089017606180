<template>
  <div v-if="pageSize" class="page-list-container">
    <div class="page-list-container-content">
      <slot></slot>
      <p v-if="total === 0" class="page-list-container-content-empty-info">
        {{ emptyInfo }}
      </p>
      <div style="flex: 1;"></div>
      <pagination2 v-if="total != 0" id="pagelist2" class="page-list-container-content-pagination" :total="total"
        :pageSize="pageSize" :fixedSize="fixedSize" :currentPage="currentPage" @pagechange="onPageChange"
        @sizechange="onSizeChange">
      </pagination2>
    </div>
  </div>
</template>

<script>
import Pagination from "@c/common/pagination.vue";
import Pagination2 from "@c/common/pagination2.vue";
export default {
  components: {
    Pagination,
    Pagination2,
  },

  props: {
    total: Number, //显隐空页面提示
    pageSize: Number,//分页器总页数
    inputCurrentPage: Number, //分页器当前页
    fixedSize: {
      type: Boolean,
      default: false
    },

    emptyInfo: {
      type: String,
      default: "没有数据",
    }
  },

  data() {
    return {
      currentPage: 1,
    };
  },

  mounted() {
    this.currentPage = this.inputCurrentPage;
  },

  watch: {
    inputCurrentPage() {
      this.currentPage = this.inputCurrentPage;
    }
  },

  methods: {
    onPageChange(currentPage) {
      this.$emit("onPageChange", currentPage);
    },
    onSizeChange(size) {
      this.$emit("onSizeChange", size);
    }
  },
};
</script>

<style lang="scss" scoped>
.page-list-container {
  background-color: white;

  &-content {
    font-size: rem(16);
    width: 100%;
    min-height: calc(100vh - 236px);
    display: flex;
    flex-direction: column;

    &-empty-info {
      color: rgba($color: black, $alpha: 0.85);
      font-size: rem(14);
      width: 100%;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-pagination {
      height: 90px;
      display: flex;
      align-items: center;
      // margin-top: rem(120);
      margin-left: auto;
      margin-right: auto;
      // margin-bottom: rem(88);
    }
  }
}
</style>