<template>
  <button class="cell-unit" @click="onClick">
    <img class="cell-unit-icon" :src="iconSRC" />
    <p class="cell-unit-title" :style="{ color: color }">{{ title }}</p>
  </button>
</template>

<script>
export default {
  props: {
    iconSRC: String,
    title: String,
    color: String,
  },

  methods: {
    onClick: function () {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>


.cell-unit {
  display: flex;
  align-items: center;

  &-icon {
    width: rem(16);
  }

  &-title {
    font-size: rem(14);
    line-height: rem(22);
    margin-left: rem(8);
  }
}
</style>