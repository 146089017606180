<template>
  <div class="pagination">
    <el-pagination @current-change="handleCurrentChange" :current-page.sync="current" :page-size="pageSize"
      layout="prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</template>

<script type="es6">
export default {
  props: {
    total: {
      // 数据总条数
      type: Number,
     
    },
    pageSize: {
      // 每页显示条数
      type: Number,
  
    },
    currentPage: {
      // 当前页码
      type: Number,
      default: 1,
    }
  },

  data() {
    return {
      current: 1,
    };
  },

  watch: {
    currentPage: function (newValue) {
      this.current = newValue;
    },
  },

  methods: {
    handleCurrentChange(currentPage) {
      this.current = currentPage
      this.$emit("pagechange", this.current);
    }
  },
};
</script>

<style lang="scss" scoped>


.pagination {
  /deep/.el-pager li {
    background-color: white;
    color: rgba($color: black, $alpha: 0.65);
    border-radius: rem(6);
    border: 1px solid rgba($color: black, $alpha: 0.15);
    min-width: rem(32);
    height: rem(32);
    margin-right: rem(8);
    line-height: rem(32);
    text-align: center;
  }

  /deep/.el-pager li.active {
    background-color: #325FAC;
    color: white;
  }

  /deep/.el-pagination .btn-prev {
    background-color: white;
    color: rgba($color: black, $alpha: 0.65);
    border-radius: rem(6);
    border: 1px solid rgba($color: black, $alpha: 0.15);
    min-width: rem(32);
    height: rem(32);
    margin-right: rem(8);
    line-height: rem(32);
    text-align: center;
  }

  /deep/.el-pagination .btn-next {
    background-color: white;
    color: rgba($color: black, $alpha: 0.65);
    border-radius: rem(6);
    border: 1px solid rgba($color: black, $alpha: 0.15);
    min-width: rem(32);
    height: rem(32);
    margin-right: rem(8);
    line-height: rem(32);
    text-align: center;
  }

  /deep/.el-pagination span:not([class*=suffix]) {
    line-height: 32px;
  }
}
</style>