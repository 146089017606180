<template>
  <div class="pagination">
    <el-pagination @current-change="handleCurrentChange" :current-page.sync="innerCurrentPage"
      :page-size.sync="innerPageSize" :page-sizes="[bestPageSize, 20, 30, 50]"
      :layout="`prev, pager, next, jumper${fixedSize ? '' : ', sizes'}`" :total="total" @size-change="handleSizeChange">
    </el-pagination>
  </div>
</template>

<script type="es6">
export default {

  props: {
    total: Number,
    pageSize: Number,
    currentPage: Number,
    fixedSize: Boolean
  },

  data() {
    return {
      innerCurrentPage: undefined,
      innerPageSize: undefined,
      bestPageSize: undefined,
    };
  },

  mounted() {
    this.bestPageSize = this.pageSize
    this.innerPageSize = this.pageSize
  },

  watch: {
    currentPage(newVal) {
      this.innerCurrentPage = newVal;
    },

    pageSize(newVal) {
      this.innerPageSize = newVal
    }

  },

  methods: {
    handleCurrentChange(currentPage) {
      this.innerCurrentPage = currentPage
      this.$emit("pagechange", this.innerCurrentPage);
    },

    handleSizeChange(currentPageSize) {
      this.innerPageSize = currentPageSize
      this.$emit("sizechange", this.innerPageSize);
    }
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  /deep/.el-pager li {
    background-color: white;
    color: rgba($color: black, $alpha: 0.65);
    border-radius: rem(6);
    border: 1px solid rgba($color: black, $alpha: 0.15);
    min-width: rem(32);
    height: rem(32);
    margin-right: rem(8);
    line-height: rem(32);
    text-align: center;
  }

  /deep/.el-pagination .el-select .el-input {
    margin: 0 35px;
  }

  /deep/.el-pager li.active {
    background-color: #325FAC;
    color: white;
  }

  /deep/.el-pagination .btn-prev {
    background-color: white;
    color: rgba($color: black, $alpha: 0.65);
    border-radius: rem(6);
    border: 1px solid rgba($color: black, $alpha: 0.15);
    min-width: rem(32);
    height: rem(32);
    margin-right: rem(8);
    line-height: rem(32);
    text-align: center;
  }

  /deep/.el-pagination .btn-next {
    background-color: white;
    color: rgba($color: black, $alpha: 0.65);
    border-radius: rem(6);
    border: 1px solid rgba($color: black, $alpha: 0.15);
    min-width: rem(32);
    height: rem(32);
    margin-right: rem(8);
    line-height: rem(32);
    text-align: center;
  }

  /deep/.el-pagination span:not([class*=suffix]) {
    line-height: 32px;
  }
}
</style>