import dayjs from 'dayjs';
export function parseClipboard(content) {
    const form = {}
    //尝试解析住院号
    const numberMatch = content.match(/住院号[:：]\s*(\S+)/);
    if (numberMatch) form.number = numberMatch[1];
    // 尝试解析姓名
    const nameMatch = content.match(/姓名[:：]\s*(\S+)/);
    if (nameMatch) form.name = nameMatch[1];

    // 尝试解析性别
    const sexMatch = content.match(/性别[:：]\s*(\S+)/);
    if (sexMatch) form.sex = sexMatch[1];

    // 尝试解析身高
    const heightMatch = content.match(/身高[:：(cm)(m)]*\s*(\d+(\.\d+)?)(cm)?(m)?(CM)?(M)?(Cm)?/);
    if (heightMatch) {
        form.height = heightMatch[1];
        // 转换m到cm
        if (!heightMatch[0].includes('cm') && form.height < 3) {
            form.height = parseFloat(form.height) * 100;
        }
        form.height = form.height.toString(); // 确保身高是整数厘米
    }

    // 尝试解析体重
    const weightMatch = content.match(/体重[:：(kg)(斤)]*\s*(\d+(\.\d+)?)/);
    if (weightMatch) {
        form.weight = weightMatch[1];
        // 转换斤到kg，假设1kg=2斤
        if (weightMatch[0].includes('斤')) {
            form.weight = parseInt(form.weight) / 2;
        }
        form.weight = form.weight.toString(); // 确保体重是字符串形式的kg
    }

    // //手机号
    var mobileLine = content.split('\n').find(line => line.includes('手机') || line.includes('电话'))
    // 去除换行符
    if (mobileLine != undefined) {
        var mobileReplace = mobileLine.replace(/[:：]/g, ':').trim();
        let mobile = mobileReplace.split(':')[1].trim();
        form.mobile = mobile
    }

    // 尝试解析出生年月
    const birthMatch = content.match(/出生年月[:：]\s*(\d{4})[年\-\.](\d{1,2})[月\-\.](\d{1,2})日?/);
    if (birthMatch) {
        const birthDate = dayjs(`${birthMatch[1]}-${birthMatch[2]}-${birthMatch[3]}`);
        const currentDate = dayjs();
        form.age = currentDate.diff(birthDate, 'year').toString(); // 计算年龄
        form.birthday = new Date(`${birthMatch[1]}-${birthMatch[2]}-${birthMatch[3]}`);
    } else {
        // 尝试解析年龄
        const ageMatch = content.match(/年龄[:：]\s*(\d+)(岁)?/);
        if (ageMatch) {
            const currentYear = new Date().getFullYear();
            const birthYear = currentYear - parseInt(ageMatch[1]);
            form.birthday = new Date(`${birthYear}-01-01`); // 默认出生月日为1月1日
            form.age = ageMatch[1];
        }
    }
    //尝试解析床位号
    const bchMatch = content.match(/床位号[:：]\s*(\S+)/);
    if (bchMatch) form.bch = bchMatch[1];
    //尝试解析部门
    const departmentMatch = content.match(/部门[:：]\s*(\S+)/);
    if (departmentMatch) form.department = departmentMatch[1];
    return form
}
